// https://jeremymarc.github.io/2013/08/20/ng-model-and-custom-form-validation
angular.module('passwordRules', []).directive("passwordRules", function() {
    // (?=.*[a-z]{2,})(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&'])[^ ]{8,}
    var upperRegex = new RegExp("[A-Z]");
    var lowerRegex = new RegExp("[a-z]");
    var numberRegex = new RegExp("[0-9]");
    var specialRegex = new RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]");
    return {
        require: "ngModel",
        scope: {},
        link: function(scope, element, attributes, ngModel) {
            ngModel.$validators.passwordRules = function(modelValue) {

                modelValue = modelValue || '';
                var explanation = "Passwords must be at least 12 characters and contain at least 3 out of 4 of the following: uppercase letter, lowercase letter, number and a special character (!@#$%^&*()+=-_)";
                explanation = "Password must be be at least 8 characters long and contain Upper/Lower case letters, numbers and symbols (!@#$&*)"
                var count = 0;

                var numberValid = numberRegex.test(modelValue);
                var upperValid = upperRegex.test(modelValue);
                var lowerValid = lowerRegex.test(modelValue);
                var specialValid = specialRegex.test(modelValue);

                if (upperValid) count++;
                if (lowerValid) count++;
                if (specialValid) count++;
                if (numberValid) count++;
                var valid = count >= 4;

                ngModel.$setValidity('passwordRulesUpper', upperValid || valid);
                ngModel.$setValidity('passwordRulesLower', lowerValid || valid);
                ngModel.$setValidity('passwordRulesNumber', numberValid || valid);
                ngModel.$setValidity('passwordRulesSpecial', specialValid || valid);
                ngModel.$setValidity('passwordRules', valid);
                return valid;
            };
        }
    };
});

/*
angular.module('$$ngApp$$').directive('passwordRules', function() {
    // (?=.*[a-z]{2,})(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&'])[^ ]{8,}
    var upperRegex = new RegExp("[A-Z]");
    var lowerRegex = new RegExp("[a-z]");
    var numberRegex = new RegExp("[0-9]");
    var specialRegex = new RegExp("[!@#$%^&*()+=-_]");
    return {
        require: 'ngModel',
        link: function(scope, elem, attr, ngModel) {

            scope.validate = function (modelValue) {
                modelValue = modelValue || '';
                var explanation = "Passwords must be at least 12 characters and contain at least 3 of the following: uppercase letter, lowercase letter, number and a special character (!@#$%^&*()+=-_)";
                var count = 0;

                var numberValid = numberRegex.test(modelValue);
                var upperValid = upperRegex.test(modelValue);
                var lowerValid = lowerRegex.test(modelValue);
                var specialValid = specialRegex.test(modelValue);

                if (upperValid) count++;
                if (lowerValid) count++;
                if (specialValid) count++;
                if (numberValid) count++;
                var valid = count >= 3;
                if (modelValue.length < 12) {
                    valid = false;
                }

                ngModel.$setValidity('passwordRulesLength', modelValue.length >= 12);
                ngModel.$setValidity('passwordRulesUpper', upperValid || valid);
                ngModel.$setValidity('passwordRulesLower', lowerValid || valid);
                ngModel.$setValidity('passwordRulesNumber', numberValid || valid);
                ngModel.$setValidity('passwordRulesSpecial', specialValid || valid);
                ngModel.$setValidity('passwordRules', valid);
                return valid;
            }

            ngModel.$parsers.unshift(function(value) {
                scope.validate(value)
                return value;
            });

            ngModel.$formatters.unshift(function(value) {
                scope.validate(value)
                return value;
            });
        }
    }
});
*/
