// https://jeremymarc.github.io/2013/08/20/ng-model-and-custom-form-validation
angular.module('compareTo', []).directive("compareTo", function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareTo"
        },
        link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareTo = function(modelValue) {
                var valid = modelValue == scope.otherModelValue;
                ngModel.$setValidity('compareTo', valid);
                return valid;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});

angular.module('compareTo').directive("compareToNot", function() {
    return {
        require: "ngModel",
        scope: {
            otherModelValue: "=compareToNot"
        },
        link: function(scope, element, attributes, ngModel) {

            ngModel.$validators.compareToNot = function(modelValue) {
                var valid = modelValue != scope.otherModelValue;
                ngModel.$setValidity('compareToNot', valid);
                return valid;
            };

            scope.$watch("otherModelValue", function() {
                ngModel.$validate();
            });
        }
    };
});

angular.module('compareTo').directive('equalsTo', function() {
    return {
        require: 'ngModel',
        scope: {
            otherModelValue: "=equalsTo"
        },
        link: function(scope, elem, attr, ngModel) {

            scope.validate = function (modelValue) {
                modelValue = modelValue || '';
                var valid = modelValue == scope.otherModelValue;
                ngModel.$setValidity('equalsTo', valid);
                return valid;
            }

            ngModel.$parsers.unshift(function(value) {
                scope.validate(value);
                return value;
            });

            ngModel.$formatters.unshift(function(value) {
                scope.validate(value);
                return value;
            });
        }
    }
});
