angular.module('fieldWrapper', []).directive('fieldWrapper', function () {
    return {
        restrict: 'A',
        scope: {
            form: '=',
            field: '@fieldWrapper',
        },
        controller: ["$scope", "$element", "$attrs", "$timeout", function ($scope, $element, $attrs, $timeout) {
            $timeout(function () {
                let updateField = function (newValue) {
                    if (!$scope.form || !$scope.field || !$scope.form.hasOwnProperty($scope.field)) {
                        return;
                    }

                    const touched = $scope.form[$scope.field].$touched;
                    const errorCount = Object.keys($scope.form[$scope.field].$error).length;

                    const error = ($scope.form.$submitted || touched) && errorCount;
                    const complete = ($scope.form.$submitted || touched) && errorCount === 0 && $scope.form[$scope.field].$valid && $scope.form[$scope.field].$modelValue && !$scope.form[$scope.field].$pristine;

                    error ? $element.addClass('error') : $element.removeClass('error');
                    complete ? $element.addClass('complete') : $element.removeClass('complete');

                    // Process server Errors
                    if ($scope.form[$scope.field] && $scope.form[$scope.field].$$attr.serverError) {
                        $scope.form[$scope.field].$setValidity('server', $scope.form[$scope.field].$modelValue && !$scope.form[$scope.field].$pristine);
                    }
                };

                $scope.$watch(`form.${$scope.field}.$valid`, updateField);
                $scope.$watch(`form.${$scope.field}.$touched`, updateField);
                $scope.$watch(`form.${$scope.field}.$modelValue`, updateField);
            }, 300);
        }]
    };
});