import '../filters/cleanConfirm';
import '../directives/compareTo';

angular.module('errorLabel', ['cleanConfirm', 'compareTo']).directive('errorLabel', function() {
    return {
        restrict: 'E',
        template:   `
            <div class="field-error" ng-show="form.$submitted || (form[field].$touched && ignoreTouched !== true)">
                <span class="error" ng-show="form[field].$error.required"> {{ label }} is required</span>
                <span class="error" ng-show="form[field].$error.email">Please enter a valid email.</span>
                <span class="error" ng-show="!form[field].$error.email && !form[field].$error.required && form[field].$error.minlength"> {{ label }} must be at least {{form[field].$$attr.minlength}} {{form[field].$$attr.type == 'number' || form[field].$$attr.hasOwnProperty('phone-input') || form[field].$$attr.hasOwnProperty('only-digits') || form[field].$$attr.hasOwnProperty('zip-input') ? 'digits' : 'characters'}}  long.</span>
                <span class="error" ng-show="form[field].$error.compareTo"> {{ label | cleanConfirm | pluralize }} do not match.</span>
                <span class="error" ng-show="form[field].$error.compareToNot"> {{ label | cleanConfirm | pluralize }} cannot be the same as {{compareToNotLabel}}.</span>
                <span class="error" ng-show="form[field].$error.server">{{ form[field].$$attr.serverError }}</span>
            </div>
                `,
        replace: true,
        scope: {
            form: '=',
            field: '@',
            label: '@?',
            ignoreTouched: '=?',
        },
        link:function(scope){
            if (!scope.label) scope.label = scope.field.substring(0,1).toUpperCase() + scope.field.substring(1);
            if (scope.form[scope.field] && scope.form[scope.field].$$attr.compareToNot) scope.compareToNotLabel = scope.form[scope.form[scope.field].$$attr.compareToNot.split(".").pop()].$$attr.placeholder;
        }
    };
});

angular.module('errorLabel').directive('passwordErrorLabel', function() {
    return {
        restrict: 'E',
        template:   `
            <div class="tooltip password-rules" ng-show="form.$showPasswordTooltip || ((form.$submitted || form[field].$touched) && form[field].$error.passwordRules)">
                <p ng-class="{success: !form[field].$error.required && !form[field].$error.minlength && !form[field].$error.maxlength }">Password must be between 8 and 25 characters.</p>
                <p ng-class="{success: !form[field].$error.passwordRulesUpper }">Password must contain at least one uppercase letter (A-Z)</p>
                <p ng-class="{success: !form[field].$error.passwordRulesLower }">Password must contain at least one lowercase letter (a-z)</p>
                <p ng-class="{success: !form[field].$error.passwordRulesNumber }">Password must contain at least one number (0-9)</p>
                <p ng-class="{success: !form[field].$error.passwordRulesSpecial }">Password must contain at least one of the following special characters: {{ allowedCharacters }}</p>
            </div>
                `,
        replace: true,
        scope: {
            form: '=',
            field: '@',
            label: '@?',
        },
        link:function(scope){
            scope.keys = Object.keys;
            scope.allowedCharacters = `! " # $ % & \' ( ) * + , - . / : ; < = > ? @ [ \\ ] ^ _ \` { | } ~`;
            if (!scope.label) scope.label = scope.field.substring(0,1).toUpperCase() + scope.field.substring(1);
        }
    };
});

